<template>
  <div class="prize_item" :style="{ margin: `${gap}px ${padding}px 0`, background: background }">
    <img :src="$img(data.picture[0] ? data.picture[0].path : '')" alt="" />
    <div class="right">
      <div class="prize_item_title">{{ data.title || "" }}</div>
      <div class="prize_item_point" v-if="type === 'POINT'">
        {{ $t('blindBox.requiredPointsPoints', { point: (data.point || 0) }) }}
      </div>
      <div class="time" v-if="type === 'RECORD'">{{ $format(data.indate, "MM/dd/yyyy HH:mm:ss") }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: 'PRIZE' | 'POINT' | 'RECORD',
      default: 'PRIZE'
    },
    data: {
      type: Object,
      default: () => ({})
    },
    gap: {
      type: Number,
      default: 14
    },
    padding: {
      type: Number,
      default: 0
    },
    background: {
      type: String,
      default: `url(${require('../images/list_item.png')})`
    }
  }
}
</script>

<style lang="less" scoped>
.prize_item {
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #fff;
  margin-top: calc(100vw / 1080 * 40);
  display: flex;
  align-items: center;
  @height: calc(100vw / 1080 * 193);
  color: #fff;
  background-image: url("../images/list_item.png");
  background-size: 100% 100% !important;
  font-size: 32px;

  >img {
    @size: @height;
    width: @size;
    height: @size;
  }

  .right {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 16px;
    height: @height;
  }
}
</style>
